import { Trans, useTranslation } from 'react-i18next'
import { WorksheetForm } from '@src/containers/Worsheet/schema-hooks'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { FormatCurrency } from '@src/services/Formatter'
import RadioButtonList from '@src/components/RadioButtonList'
import useTermPaymentsTable from '@src/containers/ViewCreditApplication/InitialApprobationPage/hooks'
import { FilteredCreditApplication } from '@src/api/credit-api'
import { useTermAmountOptions } from '@src/data/credit-application-hooks'
import { StepStateUpdater } from '@src/components/Stepper/StepperStep'
import { useEffect } from 'react'

type Props = {
  soonestDeliveryOn: Date
  creditApp: FilteredCreditApplication
  setStepState: StepStateUpdater
  setNext: () => void
}

const TermStep = ({ soonestDeliveryOn, creditApp, setStepState, setNext }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { control } = useFormContext<WorksheetForm>()

  const {
    field: { value, onChange, onBlur, ref: _ref, ...field },
  } = useController({ name: 'term', control })
  const finalAmount = useWatch({ control, name: 'amountRequested' })
  const firstPaymentOn = useWatch({ control, name: 'firstPaymentOn' })

  const [monthlyPaymentPerMonths, isLoadingTerms] = useTermPaymentsTable(creditApp, {
    firstPaymentOn,
    activationDate: soonestDeliveryOn,
    requestedAmount: Number(finalAmount),
  })
  const termOptions = useTermAmountOptions(monthlyPaymentPerMonths, resolvedLanguage!)

  useEffect(() => setStepState({ loading: isLoadingTerms }), [isLoadingTerms, setStepState])

  if (isLoadingTerms) return <div className="loader" />

  return (
    <>
      <h3 style={{ marginBottom: '16px' }}>
        <Trans i18nKey="worksheet.term.title" values={{ finalAmount: FormatCurrency(finalAmount) }} />
      </h3>
      <p>{t('worksheet.term.description')}</p>
      <div className="paragraph">
        <div className="radiobuttons-stacked-container">
          <RadioButtonList
            {...field}
            onChange={(e) => {
              onChange(parseInt(e.currentTarget.value, 10))
              onBlur()
              setNext()
            }}
            onClick={() => {
              onBlur()
              setNext()
            }}
            defaultValue={value.toString()}
            options={termOptions}
            reverse
          />
        </div>
      </div>
    </>
  )
}

export default TermStep
